'use strict';
document.addEventListener("DOMContentLoaded", function(event) {
    $(function() {
        $("#user_notify_levels, #user_notify_awards").change(function () {
            var form = $('#notificationsForm')[0];
            $.post(form.action, $(form).serialize())
                .done(function (data) {
                    console.log(data)
                });
        })
    })
});
