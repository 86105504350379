window.onload = function () {
  Intellum.util.ready(function () {
    Array.prototype.slice.call(
            document.querySelectorAll('.sidemenu__link')).map(function (link) {
      link.addEventListener('click', function (ev) {
        document.querySelector('.sidemenu__listitem--active').classList.remove('sidemenu__listitem--active');
        link.parentNode.classList.add('sidemenu__listitem--active');
      })
    });
  });
}