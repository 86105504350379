'use strict';
document.addEventListener("DOMContentLoaded", function(event) {
    $(function() {
        $('#metrics-back').click(function() {
            window.Helpers.goBack();
        });

        $('.metrics__nav-header').click(function(e) {
            $(e.currentTarget).find('svg').toggleClass('hide');
        })
    })
});
