'use strict';
document.addEventListener("DOMContentLoaded", function(event) {
    $(function() {
        $('.me__badge-item').click(function(){
            var badge_id = $(this).attr('data-overlay-id');
            $('#'+badge_id).trigger('show');
        });

        function initChart() {
            var el = document.getElementById('graph'); // get canvas

            var options = {
                percent:  el.getAttribute('data-percent') || 25,
                size: el.getAttribute('data-size') || 180,
                lineWidth: el.getAttribute('data-line') || 15,
                rotate: el.getAttribute('data-rotate') || 0,
                showPercentage: el.getAttribute('data-show-percent') || "true"
            };

            var canvas = document.createElement('canvas');




            if (typeof(G_vmlCanvasManager) !== 'undefined') {
                G_vmlCanvasManager.initElement(canvas);
            }

            var ctx = canvas.getContext('2d');
            canvas.width = canvas.height = options.size;

            //query the various pixel ratios
            var devicePixelRatio = window.devicePixelRatio || 1,
                backingStoreRatio = ctx.webkitBackingStorePixelRatio ||
                    ctx.mozBackingStorePixelRatio ||
                    ctx.msBackingStorePixelRatio ||
                    ctx.oBackingStorePixelRatio ||
                    ctx.backingStorePixelRatio || 1,


                ratio = devicePixelRatio / backingStoreRatio;

            if (devicePixelRatio !== backingStoreRatio) {
                var oldWidth = canvas.width;
                var oldHeight = canvas.height;

                canvas.width = oldWidth * ratio;
                canvas.height = oldHeight * ratio;

                canvas.style.width = oldWidth + 'px';
                canvas.style.height = oldHeight + 'px';

                // now scale the context to counter
                // the fact that we've manually scaled
                // our canvas element
                ctx.scale(ratio, ratio);
            }

            if(options.showPercentage==="true"){
                var span = document.createElement('span');
                span.textContent = options.percent + '%';
                el.appendChild(span);
            }

            el.appendChild(canvas);

            ctx.translate(options.size / 2, options.size / 2); // change center
            ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

            //imd = ctx.getImageData(0, 0, 240, 240);
            var radius = (options.size - options.lineWidth) / 2;

            var drawCircle = function(color, lineWidth, percent, gradient) {
                percent = Math.min(Math.max(0, percent || 1), 1);
                ctx.beginPath();
                ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);

                if(!!gradient){
                    var grd=ctx.createLinearGradient(0,0,100,100);
                    grd.addColorStop(0,"#00c0fd");
                    grd.addColorStop(1,"#01d3be");
                    ctx.strokeStyle = grd;
                }else
                    ctx.strokeStyle = color;
                ctx.lineCap = 'round'; // butt, round or square
                ctx.lineWidth = lineWidth;
                ctx.stroke();
            };

            drawCircle('#efefef', 4, 100 / 100);

            //If zero, we don't draw anything
            if(!!options.percent && options.percent != 0) {
                drawCircle('#555555', 15, options.percent / 100, true);
            }
        }

        //Check if the graph element is present, which means we need to render the chart
        var el = document.getElementById('graph');
        if(!!el) {
            initChart();
        }
    })
});
