'use strict';
document.addEventListener("DOMContentLoaded", function(event) {
    //do work with $
    $(function() {

        //Add a click handler for each menu item
        $("li[data-date-range]").click(function(e) {
            //Get all menu item icons and remove the active and bold class
            $(".menugrid__icon").removeClass("active");
            $(".menugrid__title").removeClass("font-weight-500");

            //Add the active and bold class to the current selected element
            $(e.currentTarget).children(".menugrid__icon").addClass("active");
            $(e.currentTarget).children(".menugrid__title").addClass("font-weight-500");

            //We hide all metrics container
            $("div[data-date-range]").addClass("hide");

            //We display the container for the selected option
            var currentDateRange = e.currentTarget.dataset.dateRange;
            $("div[data-date-range =" + currentDateRange+ " ]").removeClass("hide");

        })
    });
});

