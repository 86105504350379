/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'student/common/menu_loader'
import 'student/common/date_range_menu'
import 'student/common/helpers'

import 'student/activities/activities'
import 'student/metrics/metrics'
import 'student/me/me'
